"use strict";
(function () {
  /**
   * Init search bar
   */
  const initSearchBar = function () {
    const $container = $(".autocomplete-wrapper");
    const $resultSearchbar = $(".result-searchbar");
    const $bgDarkPage = $(".bg-result-searchbar");
    let numberOfResultsToDisplay = $container.data("by-page");
    let numberOfFiltersToDisplay = $container.data("filter-by-page");

    /**
     * Callback is success
     *
     * @param data
     */
    const isSuccess = function (data) {
      $bgDarkPage.removeClass("closed");
      $resultSearchbar.addClass("open").html(data);
      $resultSearchbar.find("input[type=checkbox]").bind("change", handleOnFiltered);
    };

    /**
     * Send request
     *
     * @param data
     * @returns {*}
     */
    const sendRequest = function (data) {
      return $.ajax({
        "method": "POST",
        "url": $container.data("search-bar-url"),
        "data": data,
        "statusCode": {
          200: isSuccess
        }
      });
    };

    /**
     * Handle on filtered
     */
    const handleOnFiltered = function () {
      let data = {
        "view": "autocomplete",
        "query": $container.find(".input-search").val(),
        "bypage": numberOfResultsToDisplay,
        "number-of_filters-to-display": numberOfFiltersToDisplay,
      };

      if (this.checked) {
        data["selected-filter"] = $(this).attr("name");
      } else {
        delete data["selected-filter"];
      }

      $resultSearchbar.find("input[type=checkbox]:checked").each(function () {
        let $nameAttr = $(this).attr("name");

        if (!data[$nameAttr]) {
          data[$nameAttr] = [];
        }
        data[$nameAttr].push($(this).val());
      });

      data["brands"] = JSON.stringify(data["brands"]);
      data["categories"] = JSON.stringify(data["categories"]);

      sendRequest(data);
    };

    /**
     * Handle on keyup
     */
    $container.find(".input-search").bind("keyup", function (e) {
      if ($(this).val().length > 2 && e.keyCode !== 13) {
        sendRequest({
          "view": "autocomplete",
          "query": $(this).val(),
          "bypage": numberOfResultsToDisplay,
          "number-of_filters-to-display": numberOfFiltersToDisplay
        });
      } else {
        $bgDarkPage.addClass("closed");
        $resultSearchbar.removeClass("open");
      }
    });

    // Close the result when the user clicks out of the results area
    $bgDarkPage.on("click", function() {
      if($resultSearchbar.hasClass("open")) {
        $(this).addClass("closed");
        $resultSearchbar.removeClass("open");
      }
    });
  };

  if (document.readyState === "complete" || document.readyState === "loaded") {
    initSearchBar();
  } else {
    document.addEventListener("DOMContentLoaded", initSearchBar);
  }
})();